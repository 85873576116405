<template>
  <div>
    <v-card>
      <v-card-text>
        <DateTimePicker
          @getDateFrom="dateFrom = $event"
          @getDateTo="dateTo = $event"
        ></DateTimePicker>
        <br>
        <v-row v-if="!$checkRole('ADVERTISERS') && !$checkRole('ADVERTISERS_USERS') && !$checkRole('PROVIDERS')">
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="advertiserId"
              :items="advertisers"
              item-text="name"
              item-value="id"
              :label="$t('Advertiser')"
              :disabled="loadingFirst"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            m="1"
          >
            <v-progress-circular
              v-if="loadingFirst"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col
            cols="6"
            xl="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              ref="camp"
              v-model="campaignId"
              :items="campaigns"
              item-text="name"
              item-value="id"
              :label="$t('CampaignFilter')"
              :disabled="loadingCampaign"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="1"
            xl="1"
            md="1"
            sm="1"
          >
            <v-progress-circular
              v-if="loadingCampaign"
              class="mt-5"
              indeterminate
              color="primary"
              :width="2"
              :size="20"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <br>
        <v-btn
          color="primary"
          @click="getDataFromDB"
        >
          {{ $t('BtnSearch') }}
        </v-btn>
      </v-card-text>
    </v-card>

    <br>
    <v-row>
      <v-col class="col-6">
        <AgeWidget
          :header="$t('AudienceElements.HeaderMale')"
          :items="dataAgeMale"
          :color="'#85bde4'"
        ></AgeWidget>
        <br>
        <AgeWidget
          :header="$t('AudienceElements.HeaderFemale')"
          :items="dataAgeFemale"
          :color="'#f78ca9'"
        ></AgeWidget>
        <br>
      </v-col>
      <v-col class="col-6">
        <v-card>
          <br>
          <vue-apex-charts
            :options="genderChartOptions"
            :series="genderSeries"
          ></vue-apex-charts>
        </v-card>
      </v-col>
    </v-row>

    <br>
    <div>
      <v-data-table
        :headers="headers"
        :items="dataCategory"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading="loading"
        :locale="$i18n.locale"
      >
        <template v-slot:top>
          <div class="row">
            <div class="col-8"></div>
            <div class="col-4">
              <v-text-field
                v-model="search"
                :label="$t('LblSearch')"
                class="mx-4"
              ></v-text-field>
            </div>
          </div>
        </template>
        <template v-slot:item.clicksPercent="{item}">
          <v-row>
            <v-col
              cols="8"
              sm="8"
              md="8"
              lg="8"
            >
              <v-progress-linear
                class="mt-2 mr-2"
                :value="item.clicksPercent"
                height="15"
                :rounded="true"
                :buffer-value="100"
              ></v-progress-linear>
            </v-col>
            <v-col
              cols="4"
              sm="4"
              md="4"
              lg="4"
            >
              <span>{{ item.clicksPercent + '%' }}</span>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      color="error"
    >
      {{ messageText }}
    </v-snackbar>
  </div>
</template>

<script>
import qs from 'qs'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import DateTimePicker from '../Components/DateTimePicker.vue'
import AgeWidget from './Widgets/AgeWidget.vue'

export default {
  components: {
    DateTimePicker,
    AgeWidget,
    VueApexCharts,
  },
  data() {
    return {
      search: '',
      advertisers: [],
      advertiserId: null,
      campaigns: [],
      loadingCampaign: false,
      loadingFirst: false,
      showMessage: false,
      messageText: '',
      campaignId: Number(this.$route.params.campaign_id),
      timeout: 5000,
      dataCategory: [],
      dataAgeMale: [],
      dataAgeFemale: [],
      genderPercent: [],
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      genderSeries: [],
      genderChartOptions: {
        chart: {
          type: 'donut',
          height: 250,
        },
        legend: {
          position: 'top',
          offsetX: 0,
          offsetY: 0,
        },
      },
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('AudienceElements.CategoryName'),
          value: this.$i18n.locale === 'ru' ? 'category.value' : `category.name${this.$ucFirst(this.$i18n.locale)}`,
        },
        { text: this.$t('AudienceElements.Percent'), value: 'clicksPercent' },
      ]
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.getDataFromDB()
    },
    advertiserId() {
      this.getCampaigns(false)
    },
  },
  mounted() {
    this.firstLoad()
  },
  methods: {
    getDataFromDB() {
      if (!this.campaignId) {
        this.messageText = this.$t('EmptyCampaignId')
        this.showMessage = true

        return
      }

      this.loading = true

      // const dateStart = this.dateFrom != null ? Math.floor(new Date(this.dateFrom).getTime() / 1000) : 0
      // const dateFinish = this.dateTo != null ? Math.floor(new Date(this.dateTo).getTime() / 1000) : Math.floor(new Date().getTime() / 1000)
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const paramsCategory = {
        dateFrom: dateStart,
        dateTo: dateFinish,
        userId: this.$getUserId(),
        campaignIds: this.campaignId,
        limit: 20,
      }

      this.$http
        .get(
          `${Vue.prototype.$apiBaseURL}/audience/category?${qs.stringify(paramsCategory, { arrayFormat: 'repeat' })}`,
        )
        .then(response => {
          this.dataCategory = response.data != null ? response.data : []
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })

      const paramsAge = {
        dateFrom: dateStart,
        dateTo: dateFinish,
        userId: this.$getUserId(),
        campaignIds: this.campaignId,
      }

      this.$http
        .get(`${Vue.prototype.$apiBaseURL}/audience/demography?${qs.stringify(paramsAge, { arrayFormat: 'repeat' })}`)
        .then(response => {
          const data = response.data != null ? response.data : []
          this.dataAgeMale = []
          this.dataAgeFemale = []
          let malePercent = 0
          let femalePercent = 0
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < data.length; i++) {
            if (data[i].gender === 'male') {
              this.dataAgeMale.push(data[i])
              malePercent += data[i].percent
            }
            if (data[i].gender === 'female') {
              this.dataAgeFemale.push(data[i])
              femalePercent += data[i].percent
            }
          }

          this.genderPercent = []
          this.genderPercent.push({
            labels: ['Female', 'Male'],
            series: [Math.round(femalePercent * 100) / 100, Math.round(malePercent * 100) / 100],
          })

          this.genderChart(this.genderPercent[0])

          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    firstLoad() {
      this.getAdvertisers().then(() => {
        this.getCampaigns(true).then(() => {
          this.getDataFromDB()
        })
      })
    },

    getAdvertisers() {
      this.loadingFirst = true

      return this.$http
        .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.advertisers = response.data
          if (this.firstLoad === true) {
            this.advertiserId = null
          }

          this.loadingFirst = false
        })
    },

    getCampaigns(isFirstLoad) {
      this.loadingCampaign = true

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
          params: { userId: this.$getUserId(), advertiserId: this.advertiserId },
        })
        .then(response => {
          this.campaigns = response.data !== null ? response.data : []
          if (isFirstLoad) {
            if (!this.campaignId) {
              if (this.campaigns.length > 0) {
                this.campaignId = this.campaigns[0].id
              } else {
                this.campaignId = null
              }
            }
          } else if (this.campaigns.length > 0) {
            this.campaignId = this.campaigns[0].id
          } else {
            this.campaignId = null
          }

          this.loadingCampaign = false
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          this.showMessage = true
          this.messageText = error
        })
    },

    genderChart(genderPercent) {
      this.genderData = genderPercent
      const translatedLabels = []
      this.genderData.labels.forEach(element => {
        translatedLabels.push(this.$t(element))
      })
      this.genderSeries = this.genderData.series
      this.genderChartOptions = {
        chart: {
          type: 'donut',
          height: 305,
        },
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,

                offsetY: -15,
                formatter(value) {
                  return `${value}k`
                },
              },
              total: {
                show: true,
                label: 'Weekly Visits',
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}k`
                },
              },
            },
          },
        },
        labels: translatedLabels,
        colors: ['#f78ca9', '#85bde4', '#ab7efd'],
      }
    },
  },
}
</script>

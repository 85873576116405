<template>
  <v-card>
    <v-card-title class="justify-center"><span class="text-center" style="word-break: normal">{{header}}</span></v-card-title>
    <v-col>
      <v-card-text class="text-center">
        <div v-for="(item) in items">
          <v-row>
            <v-row class="mt-2">
              <v-col class="text-left" :color="color">
                <span :style="{'color': color}">{{ item.age + $t('AudienceElements.Years') }}</span>
              </v-col>
              <v-col class="text-right">
                <span>{{ item.percent + '%' }}</span>
              </v-col>
            </v-row>
          </v-row>
          <v-row>
            <v-progress-linear :value="item.percent" height="15" :rounded="true"
                               :buffer-value="100" :color="color"
            ></v-progress-linear>
          </v-row>
        </div>
        <br>
      </v-card-text>
    </v-col>
  </v-card>
</template>

<script>
export default {
  name: 'AgeWidget',
  props: ['header', 'items', 'color'],
}
</script>

<style scoped>

</style>

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_c('span',{staticClass:"text-center",staticStyle:{"word-break":"normal"}},[_vm._v(_vm._s(_vm.header))])]),_c(VCol,[_c(VCardText,{staticClass:"text-center"},[_vm._l((_vm.items),function(item){return _c('div',[_c(VRow,[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{staticClass:"text-left",attrs:{"color":_vm.color}},[_c('span',{style:({'color': _vm.color})},[_vm._v(_vm._s(item.age + _vm.$t('AudienceElements.Years')))])]),_c(VCol,{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(item.percent + '%'))])])],1)],1),_c(VRow,[_c(VProgressLinear,{attrs:{"value":item.percent,"height":"15","rounded":true,"buffer-value":100,"color":_vm.color}})],1)],1)}),_c('br')],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }